<template>
  <div class="ManageCourses">


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    
    <CRow>
      <CCol>
        <h1>Liste de courses du cabinet</h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>

        <CRow>
          <CCol>
            <strong> Ajouter un nouvel item à votre liste</strong>
            <b-form v-on:submit.prevent="addCourse(newCourse.quantite, newCourse.description)">
              <CDataTable
                addTableClasses="no-border-table no-margin-bottom-table"
                :header="false"
                headVariant="light"
                :items="[newCourse]"
                :fields="newCourseRenderFields"
              >

              <template #description="">
                <td>
                  <CInput
                    placeholder="Description"
                    type="text"
                    v-model="newCourse.description"
                    @input="$v.newCourse.description.$touch()"
                    :isValid="$v.newCourse.description.$dirty ? !$v.newCourse.description.$error : null">
                  </CInput>
                </td>
              </template>

              <template #quantite="">
                <td>
                  <CInput
                    placeholder="Quantite"
                    type="number"
                    v-model="newCourse.quantite"
                    @input="$v.newCourse.quantite.$touch()"
                    :isValid="$v.newCourse.quantite.$dirty ? !$v.newCourse.quantite.$error : null">
                  </CInput>
                </td>
              </template>

              <template #actions="">
                <td>
                  <CButton
                    type="submit"
                    :color="addCourseButtonStyle"
                    square
                    :disabled="$v.newCourse.$invalid || addCourseInProcess"
                  >
                      {{addCourseButtonText}}
                  </CButton>
                </td>
              </template>
              </CDataTable>
            </b-form>
          </CCol>
        </CRow>

        <CDataTable
          headVariant="light"
          :items="coursesRender"
          :fields="coursesRenderFields"
          :noItemsView='{ noResults: "Aucun item", noItems: "Aucun item" }'
        >
        
        <template #description="{item}">
          <td>
            <span v-if="item.is_ordered" class="text-secondary" style="text-decoration: line-through;"> 
              {{item.description}}
            </span>
            <span v-else> 
              {{item.description}}
            </span>
          </td>
        </template>
        
        <template #quantite="{item}">
          <td class="text-center">
            <span v-if="item.is_ordered" class="text-secondary" style="text-decoration: line-through;"> 
              {{item.quantite}}
            </span>
            <span v-else> 
              {{item.quantite}}
            </span>
          </td>
        </template>
        
        <template #actions_ordered="{item}">
          <td>
            <div class="text-center">
              <CButton
                v-if="!item.is_ordered"
                class="ml-2"
                color="outline-primary"
                square
                size="sm"
                @click="courseOrdered(item.id)"
              >
                Marquer comme commandé <CIcon name="cil-check-circle"/>
              </CButton>
              <span v-else class="text-primary">
                <CIcon name="cil-check-circle"/> Commandé
              </span>
            </div>
          </td>
        </template>
        <template #actions_remove="{item}">
          <td>
            <CButton
              class="ml-2 text-right"
              square
              size="sm"
              @click="deleteCourse(item.id)"
            >
              <CIcon class="text-danger" name="cil-x-circle"/>
            </CButton>
          </td>
        </template>

        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucun item enregistré
            </CCol>
          </CRow>
        </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { APIUserConnected } from '@/api/APIUserConnected'
import { validationMixin } from 'vuelidate'

import { required, minLength, maxLength, integer, minValue, maxValue} from 'vuelidate/lib/validators'


import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()

export default {
  name: 'ManageCourses',
  components: {
    Loading
  },
  mixins: [
    validationMixin,
  ],
  data: function () {
    return {

      isLoading: false,

      // ------ Course  ---
      courses: [],
      coursesRender: [],
      coursesRenderFields: [
        { key: "description", label: "Description", tdClass: 'ui-helper-center', sortable: true},
        { key: "quantite", label: "Quantité", tdClass: 'ui-helper-center', sortable: true},
        { key: "actions_ordered", label: "", _classes: "text-center"},
        { key: "actions_remove", label: ""},
      ],

      // ------------ ADD COURSE --------
      newCourseRenderFields: [
        { key: "description", label: "Description", tdClass: 'ui-helper-center', sortable: true},
        { key: "quantite", label: "Quantité", tdClass: 'ui-helper-center', sortable: true},
        { key: "actions", label: "Actions"},
      ],
      newCourse: {
        'description': '',
        'quantite': '',
      },
      addCourseButtonText: 'Ajouter un item',
      addCourseButtonStyle: 'dark',
      addCourseInProcess: false,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
  },
  created: function() {
    this.getAllCourses()
  },

  validations: {
    newCourse: {
      quantite: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(1000)
      },
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(150)
      },
    },
  },

  watch: {
    courses: function (newCourse) {
      if (newCourse.length == 0) {
        this.coursesRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newCourse.length; i++) {
          final_array.push(
            {
              'id': newCourse[i].id,
              'description': newCourse[i].description,
              'quantite': newCourse[i].quantite,
              'is_ordered': newCourse[i].is_ordered,
            }
          )
        }
        this.coursesRender = final_array
      }
    },
  },
  methods: {

    getAllCourses () {
      this.isLoading = true
      apiUserConnected.getAllCourses(this.token)
      .then((result) => {
        this.courses = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    // ------------ ADD COURSE --------------
    addCourse (quantite, description) {
      this.addCourseInProcess = true
      this.addCourseButtonText = "Ajout en cours"
      this.addCourseButtonStyle = 'secondary'
      apiUserConnected.addCourse(this.token, quantite, description)
      .then(() => {
        this.getAllCourses()
        this.newCourse.description = ''
        this.newCourse.quantite = ''
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addCourseInProcess = false
        this.addCourseButtonText = "Ajouter un item"
        this.addCourseButtonStyle = 'outline-dark'
      })
    },
    
    // ---------------  COURSE ORDERED
    courseOrdered (course_pk) {
      apiUserConnected.courseOrdered(this.token, course_pk)
      .then(() => {
        this.getAllCourses()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    },
    
    // --------------- DELETE COURSE
    deleteCourse (course_pk) {
      apiUserConnected.deleteCourse(this.token, course_pk)
      .then(() => {
        this.getAllCourses()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    },
  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}

</style>
